body {
  background-color: #faeae5;
  color: #495867;
}

.name-text {
  font-family: 'Belleza', sans-serif;
  font-weight: 500;
}
.role-text {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
}

.greeting-block {
  margin-top: 8%;
  color: #c96c5c;
}
.social-links {
  color: #495867
}
.about-page {
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
}
.tagline-about-page {
  font-family: 'Belleza', sans-serif;
  font-size: 25px;
}
.about-headline {
  font-family: 'Belleza', sans-serif;
  /* font-size: 30px; */
}
#headshot {
  height: 180px;
  width: 180px;
  border-radius: 50%;
}
.toolbox-headline {
  font-family: 'Belleza', sans-serif;
  font-size: 25px;
}

.nav-bar-total {
  background-color: #495867;
  font-family: 'Source Sans Pro', sans-serif;
  font-weight: 600;
  color: #faeae5;
}
.nav-bar-total a:hover {
  padding-bottom: 8px;
  border-bottom: 4px solid #faeae5;
}
.active {
  padding-bottom: 8px;
  border-bottom: 4px solid #faeae5;
}

